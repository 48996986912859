import React, { Component } from 'react';
import ScrollToTop from "react-scroll-to-top";
import { RateQuoteForm } from './RateQuoteForm';
import OrderForm from './OrderForm';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "react-modal";
import NavMenu from './NavMenu'


// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import { Autoplay, Pagination } from "swiper";

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: 1000,
    },
};

export class Home extends Component {
    static displayName = Home.name;
       
    constructor(props) {
        super(props);
        this.state = { 
            openPrivacyModal: false,
            location: props.location
        };        
        this.baseState = this.state;       
             

    }  

    componentDidMount() {

        if (this.state.location.search === '?privacy-policy') {

            //this.setState({
            //    openPrivacyModal: true
            //});
        }
     
    }
             
    handelPrivacyModalOpen = () => {
        this.setState({ 
            openPrivacyModal: true 
        });       
    };

    handelPrivacyModalClose = () => {
        this.setState({ 
            openPrivacyModal: false 
        });
    };

    render() {
        return (
            <>
                <ScrollToTop className="scroll-top d-flex align-items-center justify-content-center active" />                          
                <NavMenu/>
                    <Modal isOpen={this.state.openPrivacyModal} onRequestClose={this.handelPrivacyModalClose} style={customStyles} ariaHideApp={false} >
                   <PrivacyPolicy/>
                </Modal>
                <section id="about" className="hero d-flex align-items-center section-bg">
                    <div className="container">
                        <div className="row justify-content-between gy-5">
                            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start pr-6">
                                <h2 data-aos="fade-up">A title company you can trust</h2>
                                <p data-aos="fade-up" data-aos-delay={100}>Purchasing a new home? Refinancing? Do you know what happens in between the time of application and closing day? Your home is your most valuable asset. At American Home Title and Escrow, we’re available to answer your questions about your closing. Our staff of dedicated professionals will guide you through the entire process so that you are protected against adverse title claims.</p>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center text-lg-start">
                                <img src="assets/img/hero_new.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay={300} />
                            </div>
                        </div>
                    </div>

                </section><main id="main">
                    <section id="why-us" className="why-us section-bg pt-0">
                        <div className="container" data-aos="fade-up">
                            <div className="row gy-4">
                                <div className="col-lg-4" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="why-box">
                                        <h3>Title insurance protects homebuyers from the following…</h3>
                                    </div>
                                </div>
                                <div className="col-lg-8 d-flex align-items-center">
                                    <div className="row gy-4">
                                        <div className="col-xl-4" data-aos="fade-up" data-aos-delay={400}>
                                            <ul>
                                                <li>Undisclosed heirs</li>
                                                <li>Forged deeds, mortgages, wills, releases and other documents</li>
                                                <li>False impersonation of the true land owner</li>
                                                <li>Deeds by minors</li>
                                                <li>Documents executed by a revoked or expired power of attorney</li>
                                                <li>False affidavits of death or heirship</li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-4" data-aos="fade-up" data-aos-delay={200}>
                                            <ul>
                                                <li>Probate matters</li>
                                                <li>Fraud</li>
                                                <li>Deeds and wills by persons of unsound mind</li>
                                                <li>Conveyances by undisclosed divorced spouses</li>
                                                <li>Rights of divorced parties</li>
                                                <li>Deeds by persons falsely representing their marital status</li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-4" data-aos="fade-up" data-aos-delay={300}>
                                            <ul className="bulleton">
                                                <li>Adverse possession</li>
                                                <li>Defective acknowledgements due to improper or expired notarization</li>
                                                <li>Forfeitures of real property due to criminal acts</li>
                                                <li>Mistakes and omissions resulting in improper abstracting</li>
                                                <li>Errors in tax records</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="quote" className="contact">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Get a rate quote</h2>
                                <p>Get title premium and title service fee quotes for your Good Faith Estimate (GFE) with the click of a button. It's simple, fast and nationwide. Submit the form and we will contact you with details.</p>
                            </div>
                            <RateQuoteForm />
                        </div>
                    </section>

                    <section id="faqs" className="events">
                        <div className="container section-header">
                            <h2>FAQs</h2>
                            <p>Title insurance is necessary for every homebuyer, but do you understand why? Even seasoned buyers don't always know the ins and outs of the title business. These questions cover the basics.</p>
                        </div>
                        <div className="container-fluid aos-init aos-animate" data-aos="fade-up">
                            <Swiper
                                speed={600}
                                loop={true}
                                spaceBetween={30}
                                slidesPerView={1}
                                autoplay={{
                                    delay: 12000,
                                    disableOnInteraction: false,
                                }}

                                pagination={{
                                    clickable: true,
                                }}

                                breakpoints={{
                                    "@0.00": {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                    "@0.75": {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                    "@1.00": {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                    "@1.50": {
                                        slidesPerView: 2,
                                        spaceBetween: 1,
                                    },
                                }}
                                modules={[Autoplay, Pagination]} className="swiper">
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>What do title companies do?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <p className="description">
                                            After your initial application, your title company takes care of many documents before closing. Our staff of dedicated professionals will take the time to guide you through the process to protect against adverse title claims long after your closing. Here’s what you can expect us to deliver along the way.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <p className="description"><b>Prior to closing</b></p>
                                                <ul className="description">
                                                    <li>Conduct title search</li>
                                                    <li>Coordinate with appropriate parties</li>
                                                    <li>Prepare necessary documents</li>
                                                    <li>Schedule closing</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <p className="description"><b>During closing</b></p>
                                                <ul className="description">
                                                    <li>Account for all funds</li>
                                                    <li>Review closing documents</li>
                                                    <li>Disburse all mortgage loan or sale proceeds</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <p className="description"><b>After closing</b></p>
                                                <ul className="description">
                                                    <li>Satisfy lender requirements</li>
                                                    <li>Record transfer documents</li>
                                                    <li>Issue title insurance policy</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>Why do you need title insurance?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <p className="description">Owning real estate is one of the most precious freedoms in this country. You want the assurance that the property you are buying will be yours. Other than your mortgage holder, no one else should have any claims or restrictions against your home.</p>
                                        <p className="description">No matter how experienced and thorough your title examiner may be, a title search cannot absolutely assure that no title faults are present. Title searches are based on a careful examination of public records, which may not—and in some cases, cannot—disclose certain types of information that may later affect your title.</p>
                                        <p className="description">While other kinds of insurance, such as auto, life or health, cover you against future losses, title insurance protects against risks caused by title faults that occurred before you owned the property. Once you pay the one-time premium, your policy remains in effect until the property is sold to a new buyer, even if that doesn't occur for decades.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>What is a lender's policy?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <p className="description">A lender's policy, also known as a loan policy or a mortgage policy, protects the lender against loss due to unknown title defects. It also protects the lender's interest from certain matters which may exist, but may not be known at the time of the sale.</p>
                                        <p className="description">This policy only protects the lender's interest. It does not protect the purchaser. That is why a real estate purchaser needs an owner's policy.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>What is an owner's policy?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <p className="description">An owner's policy protects you, the purchaser, against a loss that may occur from a fault in the ownership or interest you have in the property. You should protect the equity in your new home with a title policy.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>What does an owner's policy provide?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <ul className="description">
                                            <li>Protection from financial loss due to demands that may be charged against the title to your home, up to the amount of the title policy.</li>
                                            <li>Payment of legal costs if the title insurer has to defend your title against a covered claim.</li>
                                            <li>Payment of successful claims against the title to your home covered by the policy, up to the amount of the policy.</li>
                                        </ul>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide><div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                    <div className="price align-self-start">Q:</div>
                                    <h3>Why does the seller need to provide title insurance?</h3>
                                    <div className="price align-self-start">A:</div>
                                    <p className="description">The purchaser will need evidence that their investment in your property is free of title defects. The title insurance policy that you provide to the purchaser is a guarantee that you are selling a clear title to your real estate, unencumbered by legal attachments that might limit or jeopardize ownership. It will reassure your purchaser that he or she is protected from title risks or losses and could help you close your deal.</p>
                                </div></SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>Why does the buyer need title insurance?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <p className="description">Without title insurance, you may not be protected against errors in public records, hidden defects not disclosed by the public records or mistakes in examination of the title. As a result, you may be held accountable for any prior liens, judgments or claims brought against your new property. If this should occur, your title policy insures that you will be defended at no cost against all covered claims up to the amount of the policy.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide event-item d-flex flex-column justify-content-start">
                                        <div className="price align-self-start">Q:</div>
                                        <h3>How much does title insurance cost?</h3>
                                        <div className="price align-self-start">A:</div>
                                        <p className="description">The insurance commission approves and controls the premiums for title insurance policies. The premiums are paid only once and the cost depends upon the purchase price of the property. The policy amount must be equal to the purchase price. Call <a href="tel:303.426.0990">303.426.0990</a> for details.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </section>
                    <section id="contact" className="contact">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Contact us</h2>
                                <p>Need help? Contact us for assistance.</p>
                            </div>
                            <div className="row gy-4">
                                <div className="col-md-4">
                                    <div className="info-item  d-flex">
                                        <i className="icon bi bi-map flex-shrink-0" />
                                        <div>
                                            <h3>Colorado/Florida</h3>
                                            <p><a href="https://maps.app.goo.gl/cukd4ds3K4hxWjWUA" target="_blank" rel="noreferrer">4350 S. Monaco Street, Suite 150<br />Denver, CO 80237</a></p>
                                            <p>Phone: 303.426.0990</p>
                                            <p>Fax: 720.482.8500</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-item d-flex">
                                        <i className="icon bi bi-map flex-shrink-0" />
                                        <div>
                                            <h3>Maryland/Virginia/ Pennsylvania</h3>
                                            <p><a href="https://goo.gl/maps/gCg9sU8vfLNSPydw9" target="_blank" rel="noreferrer">130 W. Piccadilly Street<br />Winchester, VA 22601</a></p>
                                            <p>Phone: 703.494.3662</p>
                                            <p>Fax: 703.390.1869</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-item  d-flex">
                                        <i className="icon bi bi-map flex-shrink-0" />
                                        <div>
                                            <h3>Nevada</h3>
                                            <p><a href="https://goo.gl/maps/oTisQ6eGP1TTGty5A?coh=178571&entry=tt" target="_blank" rel="noreferrer">770 E. Warm Spring Road, Suite 250A<br />Las Vegas, NV 89119</a></p>
                                            <p>Phone: 702.638.4480</p>
                                            <p>Fax: 303.488.4847 or 800.721.9187</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="placeorder" className="contact">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Place an order</h2>
                                <p>Fill out the form below to place your online order now. *Required fields</p>
                            </div>

                            <OrderForm />

                        </div>
                    </section>
                </main>
                <Footer />
            </>

        );
    }
}
